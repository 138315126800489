/* eslint-disable array-callback-return */
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LockIcon from "@mui/icons-material/Lock";
import { roles } from "../constants/roles";
import { getReports } from "../services/reportsService";
import { replaceText } from "./replaceText";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Notifications } from "@mui/icons-material";
export const setSidebarItems = async (role, token, displayError, dispatch) => {
  let sidebarItems = [];
  if (role === roles.ROLE_OPERATOR) {
    sidebarItems = [
      { title: "Inicio", icon: <ViewQuiltIcon />, path: "/" },
      {
        title: "Socios",
        icon: <PeopleAltIcon />,
        subItems: [
          {
            title: "Alta/Modif. de Datos",
            path: "/personas/socios",
          },
          {
            title:"Error de Datos",
            path: "/personas/error",
          },
          {
            title:"Bajas de Datos",
            path: "/personas/bajas",
          },
          {
            title:"Conciliador de Cobranzas",
            path: "/persona/conciliador-cobranzas",
          },
          {
            title:"Indicador de Permanecia",
            path: "/persona/indicador-permanecia",
          },
        ],
      },
      { title: "Profesionales",
        icon: <AccountBoxIcon />,
        subItems: [
          {
            title: "Registro Profesionales",
            path: "/profesional/registro",
          },
          {
            title: "Estado Cola",
            path: "/profesional/estado-cola",
          }
        ],
      },
      {
        title: "Configuración",
        icon: <BuildCircleIcon />,
        subItems: [
          {
            title: "Obra Social",
            path: "/obra-social/socios",
          },
          {
            title: "Planes",
            path: "/configuracion/planes",
          },
        ],
      },
      {
        title: "Notificaciones",
        icon: <Notifications />,
        subItems: [
          {
            title: "Consulta Finalizada",
            path: "/notificaciones?consultation_finished",
          },
        ],
      },
      {
        title: "Empresas",
        icon: <PeopleAltIcon />,
        subItems: [
          {
            title: "Alta/Modif. de Datos",
            path: "/empresas/listado",
          },
          // {
          //   title: "Planes por convenio",
          //   path: "/empresas/planes",
          // },
          {
            title: "Convenios",
            path: "/empresas/convenios",
          }
        ],
      },
      {
        title: "Canales",
        icon: <TrendingUpIcon />,
        subItems: [
          {
            title: "Voy en Bus",
            path: "/canales/voyenbus",
          },
          {
            title: "GigaRed",
            path: "/canales/gigared",
          }
        ],
      },
    ];
  } else if (role === roles.ROLE_SELLER) {
    sidebarItems = [
      { title: "Inicio", icon: <ViewQuiltIcon />, path: "/" },
      { title: "Vender", icon: <BusinessCenterIcon /> },
      { title: "Mis Ventas", icon: <MoveToInboxIcon />, path: "/mis-ventas" },
      { title: "Actualizar contraseña", icon: <LockIcon /> },
    ];
  } else if (role === roles.ROLE_GIGARED) {
    sidebarItems = [
      {
        title: "Canales",
        icon: <TrendingUpIcon />,
        subItems: [
          {
            title: "GigaRed",
            path: "/canales/gigared",
          },
        ],
      }
    ]
   }


  const reports = await getReports(token);
  dispatch({ reports: reports });
  let subItems = [];

  reports?.map((element) => {
    element.slug = replaceText(element.name, displayError);
    subItems.push({
      title: element.name,
      path: "/reportes?" + replaceText(element.name),
    });
  });
  if (subItems.length !== 0 && role === roles.ROLE_OPERATOR) {
    sidebarItems.push({
      title: "Indicadores Gestión",
      icon: <AssessmentIcon />,
      subItems: subItems,
    });
  }
  sidebarItems.push({
    title: "Cerrar Sesión",
    icon: <LogoutIcon />,
    path: "/login",
    action: "LOG_OUT",
  });
  return sidebarItems;
};
