import axios from 'axios';

const reportsUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/reports`;

export const getReports = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data
        .filter((element) => element.name !== 'Dashboard')
        .filter((element) => element.name !== 'Error Triunfo Motos')
        .filter((element) => element.name !== 'Ventas Voy En Bus')
        .filter((element) => element.name !== 'Reporte Altas GigaRed')
        .filter((element) => element.name !== 'Bajas Socios')
        .filter((element) => element.name !== 'Registros Profesionales')
        .filter((element) => element.name !== 'Conciliador de Cobranzas')
        .filter((element) => element.name !== 'Estado de Cola')
        .filter((element) => element.name !== 'Indicador de Permanecia')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsDashboard = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Dashboard')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsErrorDatos = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Error Triunfo Motos')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsGigaRed = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Reporte Altas GigaRed')
        //console.log(reports);
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsVoyEnBus = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Ventas Voy En Bus')
        //console.log(reports);
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsLowMember = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Bajas Socios')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsRegisterProfessional = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Registros Profesionales')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsCollectionsConciliator = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Conciliador de Cobranzas')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsStatusQueue = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Estado de Cola')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}

export const getReportsPermanencyIndicator = async (token, displayError = () => {}) => {
    return await axios.get(reportsUrl,{ headers: {authorization: `Bearer ${token}`}} )
    .then((response) => {
        const reports = response.data.filter((element) => element.name === 'Indicador de Permanecia')
        return reports;
    })
    .catch((error) => {
        if(token) displayError("getReports error: " + error.message)
    });
}