import {useEffect, useState} from "react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { ClientTable } from "../components/clientTable";
import { getPatients } from "../services/patientsService";
import { useStateAuthValue } from "../context/AuthState";
import { useToast } from "../context/Toast/ToastProvider";
import { showToast, ToastType } from "../utils/ToastUtil";
import { LoadingModal } from "../components/Modals/LoadingModal";

const Users = () => {
  const [{userToken}] = useStateAuthValue();
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const toastDispatch = useToast();
  const displayError = (msg) => showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  
  const getPatientsData = async (params) => {
    const response = await getPatients(userToken, displayError, params);
    setData(response?.patients);
    setDataLength(response?.patients?.length)
    setCurrentPage(response?.currentPage);
    setTotalPages(response?.totalPages > 1 && response?.currentPage === 0 ? -1 : response?.totalPages);
  }
  useEffect(() => {
    setLoading(true);
    getPatientsData()
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToken]);

  return (
    <ThemeProvider theme={MuiTheme}>
      <div className="users">
        <div className="users-table-container">
          <Box
            sx={{
              background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
              padding: "10px",
              borderRadius: "15px",
              color: "white",
              height: "80px",
              display: "flex",
              alignItems: "center",
              width: "90%",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 700",
                letterSpacing: " 1px",
              }}
            >
              Listado de socios
            </Typography>
          </Box>
          <ClientTable 
          data={data} 
          getPatientsData={getPatientsData}
          setLoading={setLoading}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          dataLength={dataLength}
          currentPage={currentPage}
          />
        </div>
      </div>
      {loading && <LoadingModal />}
    </ThemeProvider>
  );
};

export default Users;
