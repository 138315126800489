import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import SellerDashboard from "./pages/sellerDashboard";
import Patients from "./pages/patients";
import Financers from "./pages/financers";
import FinancerPlans from "./pages/financerPlans";
import Plans from "./pages/plans";
import Agreements from "./pages/agreements";
import CollapsibleTable from "./pages/socialWork";
import Profetionals from "./pages/profetionals";
import Login from "./pages/login";
import ProtectedRoute from "./utils/ProtectedRoute";
import NewClientForm from "./pages/newClientForm";
import MySales from "./pages/mySales";
import { roles } from "./constants/roles";
import Reports from "./pages/reports";
import { useStateAuthValue } from "./context/AuthState";
import { checkAuth } from "./services/authService";
import ErrorDatos from "./pages/dataErrors";
import LowMember from "./pages/lowMember";
import RegisterProfessional from "./pages/registerProfessional";
import CollectionsConciliator from "./pages/collectionsConciliator";
import StatusQueue from "./pages/statusQueue";
import PermanencyIndicator from "./pages/permanencyIndicator";
import VoyEnBusReport from "./pages/voyEnBusReport";
import GigaRed from "./pages/gigared";
import Notifications from "./pages/notifications";

const App = () => {
  const [{userRole, userToken}] = useStateAuthValue();

  useEffect(() => {
    if (userToken) checkAuth(userToken)
  }, [userToken]);

  return (
    <BrowserRouter>
      <Routes>
        {!userRole && <Route
          element={
            <ProtectedRoute>
              <Login />
            </ProtectedRoute>
          }
        />}
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/nuevo-cliente"
          element={
            <ProtectedRoute>
              <NewClientForm />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_SELLER && <Route
          exact
          path="/mis-ventas"
          element={
            <ProtectedRoute>
              <MySales />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_SELLER && <Route
          exact
          path="/nuevo-cliente"
          element={
            <ProtectedRoute>
              <NewClientForm />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/personas/socios"
          element={
            <ProtectedRoute>
              <Patients />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/personas/error"
          element={
            <ProtectedRoute>
              <ErrorDatos />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/canales/voyenbus"
          element={
            <ProtectedRoute>
              <VoyEnBusReport />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/profesional/registro"
          element={
            <ProtectedRoute>
              <RegisterProfessional />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/profesional/estado-cola"
          element={
            <ProtectedRoute>
              <StatusQueue />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/persona/conciliador-cobranzas"
          element={
            <ProtectedRoute>
              <CollectionsConciliator />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/persona/indicador-permanecia"
          element={
            <ProtectedRoute>
              <PermanencyIndicator />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/personas/bajas"
          element={
            <ProtectedRoute>
              <LowMember />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/empresas/listado"
          element={
            <ProtectedRoute>
              <Financers />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/notificaciones"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/configuracion/planes"
          element={
            <ProtectedRoute>
              <Plans />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/empresas/planes"
          element={
            <ProtectedRoute>
              <FinancerPlans />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/empresas/convenios"
          element={
            <ProtectedRoute>
              <Agreements />
            </ProtectedRoute>
          }
        />}
        {userRole === roles.ROLE_OPERATOR && <Route
          exact
          path="/obra-social/socios"
          element={
            <ProtectedRoute>
              <CollapsibleTable />
            </ProtectedRoute>
          }
        />}
        {(userRole === roles.ROLE_OPERATOR || userRole === roles.ROLE_GIGARED) && <Route
          exact
          path="/canales/gigared"
          element={
            <ProtectedRoute>
              <GigaRed />
            </ProtectedRoute>
          }
        />}
        <Route
          exact
          path="/personas/profesionales"
          element={
            <ProtectedRoute>
              <Profetionals />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/reportes"
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
